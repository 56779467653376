import { useEffect, useState } from 'react'
import { server } from '../config/server'

export const useGeolocation = () => {
  const [location, setLocation] = useState({})

  useEffect(() => {
    ;(async () => {
      const res = await fetch(
        `https://backend.fanclan.io/api/utils/geolocation`
      )
      const loc = await res.json()
      setLocation(loc)
    })()
  }, [])

  return location
}
