import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'
import { BlocksLoader } from '../placeholder/blocks-loader'

export const CoverFlowSlide = ({
  name,
  username,
  profile_url,
  avatar_url,
  site,
  links,
}) => {
  const [showLoader, setShowLoader] = useState(false)

  return (
    <article onClick={() => setShowLoader(true)}>
      <div
        className={
          'cf-Slide block md:min-w-[379px] w-full overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700 animate-[fadeIn_1s_linear]'
        }
      >
        <figure className='relative'>
          {showLoader && (
            <div className='absolute flex flex-col items-center justify-center rounded-2xl t-0 l-0 h-full w-full z-10 bg-[#ffffffee] dark:bg-[var(--darkmode-bg-tr)]'>
              <BlocksLoader />
              <span className='text-jacarta-300'>Loading...</span>
            </div>
          )}
          <Link legacyBehavior href={`model/${username}`}>
            <a>
              <img
                src={`${avatar_url}?class=featuredcarousel`}
                alt={name}
                className='swiper-lazy h-[430px] w-full object-cover'
                height='430'
                width='379'
              />
            </a>
          </Link>
        </figure>
        <div className='p-6'>
          <div className='flex'>
            <Link legacyBehavior href={site.url}>
              <a className='shrink-0'>
                <img
                  src={site.logo} // TODO: replace
                  alt='site avatar'
                  className='mr-4 h-10 w-10 rounded-full'
                />
              </a>
            </Link>
            <div>
              <Link legacyBehavior href={`model/${username}/`}>
                <a className='block'>
                  <span className='font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white'>
                    {name}
                  </span>
                </a>
              </Link>
              <Link legacyBehavior href={profile_url}>
                <a className='text-2xs text-accent brightness-150'>
                  @{username}
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
