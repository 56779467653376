import { useQuery } from 'react-query'

import { useReduxSearchParams } from './useReduxSearchParams'
import { buildLocationQueryString } from '../helpers/build_location_query'
import { useGeolocation } from './useGeolocation'

export const useGetNearbyModels = () => {
  const params = useReduxSearchParams()
  const location = useGeolocation()

  const nearbyModels = useQuery(
    ['nearby-models', { ...params, location }],
    getNearbyModels
  )

  return nearbyModels
}

async function getNearbyModels({ queryKey: [_, params] }) {
  const { city } = params?.location

  const queryString = buildLocationQueryString(params)

  const modelsRes = await fetch(`https://backend.fanclan.io/api/${queryString}`)
  const nearbyModels = await modelsRes.json()

  return { ...nearbyModels, city }
}
