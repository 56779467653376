import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { coverflowProps } from './coverflow-props'
import { CoverFlowSlide } from './coverFlowSlide'

import 'swiper/css'
import 'swiper/css/navigation'
import { BACKUP_COVERFLOW_DATA } from './backup-coverflow-data'

const CoverflowCarousel = ({ data }) => {
  const [delaying, setDelaying] = useState(true)

  // fade in carousel
  useEffect(() => {
    setTimeout(() => {
      setDelaying(false)
    }, 500)
  }, [])

  const slideData = Array.isArray(data) ? data : BACKUP_COVERFLOW_DATA

  return (
    <div
      className={`relative px-6 sm:px-0 transition-opacity duration-800 ${
        delaying ? 'opacity-0' : 'opacity-1'
      }`}
    >
      <Swiper {...coverflowProps}>
        {slideData.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <CoverFlowSlide {...slide} />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div className='swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='24'
          height='24'
          className='fill-jacarta-700 group-hover:fill-accent'
        >
          <path fill='none' d='M0 0h24v24H0z' />
          <path d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' />
        </svg>
      </div>
      <div className='swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='24'
          height='24'
          className='fill-jacarta-700 group-hover:fill-accent'
        >
          <path fill='none' d='M0 0h24v24H0z' />
          <path d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' />
        </svg>
      </div>
    </div>
  )
}
export default CoverflowCarousel
