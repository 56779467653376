export const buildLocationQueryString = (queryParams) => {
  const { radius, unit, page, gender, currentSort, tags } = queryParams
  const { latitude: lat, longitude: long } = queryParams?.location

  const [ageMin, ageMax] = queryParams?.ageRange ?? ['', '']
  const useAgeRange = queryParams?.ageRange?.every((a) => a !== '') ?? false
  const ageRange = `${ageMin}__${ageMax}`

  const queryString = `
    models/
    ?location__geo_distance=${radius}${unit}
    __${lat}__${long}
    &page=${page ?? 1}
    &page_size=10
    ${useAgeRange ? `&age__range=${ageRange}` : ''}
    &gender=${gender ?? ''}
    &ordering=${currentSort ?? '-views'}
    ${tags ?? ''}
    `
    .replaceAll('\n    ', '')
    .trim()

  console.log('_________queryString_________: ', queryString)
  return queryString
}
