import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { HeadLine } from '../component'
import { ModelCarousel } from '../model/modelCarousel'
import Link from 'next/link'
import { BlocksLoader } from '../placeholder/blocks-loader'
import { useDispatch, useSelector } from 'react-redux'
import { startResultsLoading } from '../../redux/counterSlice'

/*
  * COMPONENT PLANNING *
  ? Should this component fetch its own data, or should it get data from props?
  # CONSIDER THE FOLLOWING:
  # App uses dehydrated data. This may not work with something as specific as location coordinates.
  # 

*/

export const ClosestModelsCarousel = ({ data }) => {
  const { data: nearbyData, isSuccess } = data

  const nearbyModels = nearbyData?.results

  const dispatch = useDispatch()
  const { resultsLoading } = useSelector(({ counter }) => counter)

  const [headlineMessage, setHeadlineMessage] = useState('')
  const [showCarousel, setShowCarousel] = useState(false)

  useEffect(() => {
    if (!isSuccess) return

    if (nearbyModels.length >= 4) {
      setShowCarousel(true)
    }

    /** */

    const { city } = nearbyData
    setHeadlineMessage(() => (city ? `Models near ${city}` : 'Models near you'))
  }, [isSuccess, nearbyData, nearbyModels])

  if (!showCarousel) return <></>

  return (
    <section className='dark:bg-jacarta-800 bg-light-base py-24'>
      <div className='container'>
        <HeadLine
          text={headlineMessage}
          classes='font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white'
        />

        <div className='relative'>
          <ModelCarousel cards={nearbyModels} />
        </div>
      </div>
      <div className='w-full flex items-center justify-center my-6'>
        <Link
          href='/location'
          className='relative text-accent font-bold mt-0 hover:text-accent-lighter '
          onClick={() => dispatch(startResultsLoading())}
        >
          <span className='bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all'>
            View More
          </span>
          <span className='absolute left-1/2 top-[60%] -translate-x-1/2'>
            {resultsLoading && <BlocksLoader small />}
          </span>
        </Link>
      </div>
    </section>
  )
}
