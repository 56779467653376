export const parseDehydratedState = (key, { queries }) => {
  const data = queries.find((q) => {
    // find string or array type query key
    if (typeof q.queryKey === 'string') {
      return q.queryKey === key
    } else {
      return q.queryKey.find((qk) => qk === key)
    }
  })?.state.data

  // if no matching key return null
  if (!data) return null

  return mapCachedPropertiesToFetched(data)
}

/*
  # Sometimes the data returned from the server is not in the 
  # same format as the data returned from the cache. This function
  # maps the data from the cache to the data shape expected by the 
  # component.
*/
export function mapCachedPropertiesToFetched(data) {
  let hasResults = data?.pages?.length > 0
  let needsMapping = hasResults ? Array.isArray(data.pages[0].hits) : false

  if (!needsMapping) return data // return data without mapping
  
  data.pages[0].results = data.pages[0].hits.map((hit) => {
    return { ...hit.document }
  })

  data.pages[0].count = data.pages[0].found

  return data
}
