import {
  Navigation,
  Pagination,
  EffectCoverflow,
  Ally,
} from 'swiper'

export const coverflowProps = {
  breakpoints: {
    // when window width is >= 640px
    100: {
      // width: 640,
      slidesPerView: 1,
    },
    575: {
      // width: 640,
      slidesPerView: 3,
    },
    // when window width is >= 768px
    992: {
      // width: 768,
      slidesPerView: 5,
    },
  },
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 5,
  loop: true,
  centeredSlides: true,
  coverflowEffect: {
    rotate: 30,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: false,
  },
  pagination: true,
  modules: [EffectCoverflow, Pagination, Navigation],
  navigation: {
    nextEl: '.swiper-button-next-4',
    prevEl: '.swiper-button-prev-4',
  },
  className: 'swiper coverflow-slider !py-5 animate-[fadeIn_.5s_linear]',
}
