import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

import { ResultCard } from '../resultCard'

import 'swiper/css/navigation'
import 'swiper/css'

export const ModelCarousel = ({ cards = [] }) => {
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={30}
        slidesPerView='auto'
        loop={true}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          565: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: '.bids-swiper-button-next',
          prevEl: '.bids-swiper-button-prev',
        }}
        className=' card-slider-4-columns !py-5'
      >
        {cards.map((item) => {
          return (
            <SwiperSlide className='text-white' key={item.id}>
              <ResultCard {...item}/>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className='group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden'>
        <MdKeyboardArrowLeft />
      </div>
      <div className='group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden'>
        <MdKeyboardArrowRight />
      </div>
    </>
  )
}