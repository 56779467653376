import React, { Fragment, useState } from 'react'
import Link from 'next/link'

import { SearchWithSectionSuggest } from '../auto-suggest-sections/suggest-sections'

const Hero_4 = () => {
  const [inputInject, setInputInject] = useState(null)

  return (
    <section className='hero relative py-20 md:pt-32' id='hero-section'>
      <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden'>
        <img src='/images/gradient.jpg' alt='gradient' />
      </picture>
      <picture className='pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block'>
        <img src='/images/gradient_dark.jpg' alt='gradient dark' />
      </picture>

      <div className='container'>
        <div className='relative mx-auto max-w-2xl pt-24 text-center'>
          <h1 className='mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl'>
            Discover & Search Your
            <span className='animate-gradient'> Favorite Models</span>
          </h1>

          {/* <!-- Search --> */}
          <div className='relative mb-4 hero-search'>
            <SearchWithSectionSuggest
              useLoader={true}
              externalInput={inputInject}
              placeholder={'Search by Username, Categories or Bio'}
            />
          </div>

          <div className='text-jacarta-500 dark:text-jacarta-300'>
            Popular searches: &nbsp;
            {['Asian', 'Feet', 'Blonde'].map((tag, i) => (
              <Fragment key={tag}>
                <span
                  onClick={() => setInputInject(tag)}
                  className='cursor-pointer text-accent inine-block hover:text-jacarta-700 dark:hover:text-white'
                >
                  {tag}
                </span>
                {i !== 2 && <span className='text-accent'> • </span>}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero_4
