export const BACKUP_COVERFLOW_DATA = [
  {
    id: '71b73673-dcc5-4efd-8a6a-4b62d78dd62b',
    name: 'Bryce Adams 💪🍑',
    username: 'fitbryceadams',
    avatar_url:
      'https://public.onlyfans.com/files/m/mg/mgm/mgmlsib3hy9wptclnsaorjohfdwo7kmv1660441145/107987054/avatar.jpg',
    favorited_count: 8305747,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'ddbe6a67-8d1f-48a6-a9a4-780d0511aae0',
    name: 'Mags.IRL',
    username: 'mags.irl',
    avatar_url:
      'https://public.onlyfans.com/files/g/gp/gpc/gpce3p8oxzoo7dnaeuqaobuww3sledty1660411492/15643154/avatar.jpg',
    favorited_count: 7585884,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'd4071383-8b8e-40b1-81dc-64cdf53b935d',
    name: 'Ginny Potter🍆💦',
    username: 'ginnypotter',
    avatar_url:
      'https://public.onlyfans.com/files/s/su/sum/sumt8dwfzv5rnsjrgk3yc613foaulhlc1647126340/9801115/avatar.jpg',
    favorited_count: 5652133,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'e776d210-b161-4123-9ec0-750e22fff335',
    name: 'lilianaheartsss',
    username: 'lilianaheartsss',
    avatar_url:
      'https://public.onlyfans.com/files/z/zq/zqo/zqonuayzqrqkbghfzxm5dtw5a7ccljhq1657724960/7903465/avatar.jpg',
    favorited_count: 5155947,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: '2feb6b1f-b22f-4c0a-b69e-c658148f94c6',
    name: 'Peyton Kinsly🍑',
    username: 'peyton.kinsly',
    avatar_url:
      'https://public.onlyfans.com/files/7/7u/7u8/7u884mwx45fee04zfcwzrpqb0tc7q3wn1648160938/72089259/avatar.jpg',
    favorited_count: 5038370,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'd7aaab36-4b11-46b3-a66c-a124f75472a3',
    name: 'Jessica Nigri',
    username: 'jessicanigri',
    avatar_url:
      'https://public.onlyfans.com/files/m/mj/mjs/mjsmkkonztx4vuwbh8xaqe46ukjtulom1654636157/18514343/avatar.jpg',
    favorited_count: 4870300,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'de9649b3-da1f-4e4f-9757-992598b18a68',
    name: 'PeachJars',
    username: 'peachjars',
    avatar_url:
      'https://public.onlyfans.com/files/h/hp/hpk/hpkh5awsknusmxmwqkaegtb3psovu69h1615508313/avatar.jpg',
    favorited_count: 4361149,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: '56710810-95a5-4cfd-9ca8-1291da745516',
    name: 'Natalie Monroe | Live Shows',
    username: 'nataliemonroe',
    avatar_url:
      'https://public.onlyfans.com/files/v/vm/vmb/vmbkswqefhodb0nruv1czuw3zhrxtkec1593731977/avatar.jpg',
    favorited_count: 4166513,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: 'd194164f-9d1c-453e-830d-8c36f5187c0a',
    name: 'SuicideGirls',
    username: 'suicidegirls',
    avatar_url:
      'https://public.onlyfans.com/files/u/ux/uxb/uxbdkfrck5z7tpzxbgeuem3lckvtvnwo1664815361/8996552/avatar.jpg',
    favorited_count: 4083935,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
  {
    id: '4bf7d0e3-6b63-47ea-b8db-2b53db36f097',
    name: 'Sara Mei Kasai',
    username: 'sarameikasaifree',
    avatar_url:
      'https://public.onlyfans.com/files/x/xp/xp1/xp1p0bxezxx1d7v13zjelst2y6h6vqbq1639334571/avatar.jpg',
    favorited_count: 3093388,
    site: {
      id: '5b8272b8-b8da-488c-8452-0c1174a53ca9',
      name: 'OnlyFans',
      url: 'https://onlyfans.com/',
      logo: 'https://logo.clearbit.com/https://onlyfans.com/',
      is_adult: true,
    },
  },
]