import React, { useContext, useEffect } from 'react'
import { dehydrate, QueryClient } from 'react-query'

import { fetchDataServerSide } from '../helpers/fetch-data-ss'

import UserContext from '../components/UserContext'
import CoverflowCarousel from '../components/carousel/coverflowCarousel'
import Hero from '../components/hero/hero_4'
import Meta from '../components/Meta'
import { Browse_category } from '../components/component'
import { parseDehydratedState } from '../helpers/parse_dehydrated_state'
import { useReduxLoadingState } from '../hooks/useReduxLoadingState'
import { ClosestModelsCarousel } from '../components/carousel/ClosestModelsCarousel'
import { useGetNearbyModels } from '../hooks/useGetNearbyModels'

export default function Home({ dehydratedState }) {
  const { scrollRef } = useContext(UserContext)
  const { endLoading } = useReduxLoadingState()

  const top10data = parseDehydratedState('top-10', dehydratedState)
  const tagData = parseDehydratedState('top-tags', dehydratedState)
  const nearbyModels = useGetNearbyModels()

  useEffect(() => {
    // reset scroll position??
    window.scrollTo(0, scrollRef.current.scrollPos)
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY
    }
    window.addEventListener('scroll', handleScrollPos)
    return () => {
      window.removeEventListener('scroll', handleScrollPos)
    }
  })

  useEffect(() => {
    // stop loader when mounted
    endLoading()
  }, [])

  return (
    <div>
      <Meta title='FanClan | Discover & Search Your Favorite Models' />
      <Hero />
      <CoverflowCarousel data={top10data} />
      <Browse_category data={tagData} />
      <ClosestModelsCarousel data={nearbyModels} />
    </div>
  )
}

export async function getStaticProps() {
  try {
    const queryClient = new QueryClient()
    await queryClient.prefetchQuery(
      'top-10',
      async () => fetchDataServerSide(`links`),
      {
        throwOnError: true,
      }
    )
    await queryClient.prefetchQuery(
      'top-tags',
      async () => {
        const { results } = await fetchDataServerSide(
          `tags/?display_on_homepage=true&page_size=9999`
        )
        return results
      },
      {
        throwOnError: true,
      }
    )
    await queryClient.prefetchQuery(
      'all-tags',
      async () => fetchDataServerSide(`tags/?page_size=9999`),
      {
        throwOnError: true,
      }
    )

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
    }
  } catch (error) {
    console.error('Error: ', error)
    return {
      props: { dehydratedState: null },
    }
  }
}
